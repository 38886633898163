import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-77cf89f4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "title",
  class: "title-1 title"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("header", {
    class: "title-container",
    style: _normalizeStyle({ height: `${_ctx.titleWidth}px` })
  }, [
    _createElementVNode("h1", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 512)
  ], 4))
}