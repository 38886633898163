
import { computed, defineComponent, nextTick, onMounted, ref } from 'vue';
import youTubePlayerFactory from 'youtube-player';
import { YouTubePlayer } from 'youtube-player/dist/types';

import Title from '@/components/Title.vue';
import { videoService } from '@/services/videos';
import { Video } from '@/types';
import VideoItem from './Video.vue';
import { useReveal } from '@/utils';

export default defineComponent({
  components: { Title, VideoItem },
  setup() {
    const reveal = useReveal(100);
    let player: YouTubePlayer;
    const playerContainer = ref<HTMLDivElement>();
    const selectedVideo = ref<Video>();
    const videos = ref<Video[]>([]);

    const selectedVideoId = computed<string | undefined>(() => {
      if (selectedVideo.value) {
        return selectedVideo.value.resourceId.videoId;
      }
    });

    const initializePlayer = () => {
      player = youTubePlayerFactory('player-element', {
        width: '100%',
        height: '100%',
        videoId: selectedVideoId.value,
        playerVars: {
          color: 'white',
        },
      });
    };

    const setVideo = (video: Video) => {
      player.loadVideoById(video.resourceId.videoId);
      playerContainer.value?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    };

    onMounted(() => {
      videoService.getVideos().then((result) => {
        if (result.length) {
          videos.value = result;
          selectedVideo.value = result[0];
          initializePlayer();
          nextTick(() => reveal());
        }
      });
    });

    return {
      playerContainer,
      selectedVideo,
      videos,
      setVideo,
    };
  },
});
