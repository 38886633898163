
import { defineComponent, onMounted, onUnmounted, ref } from 'vue';

export default defineComponent({
  setup() {
    const title = ref<HTMLElement>();
    const titleWidth = ref(0);

    const setContainerWidth = () => {
      titleWidth.value = title.value?.clientWidth || 0;
    };

    onMounted(() => {
      setContainerWidth();
      window.addEventListener('resize', setContainerWidth);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', setContainerWidth);
    });

    return { title, titleWidth };
  },
});
