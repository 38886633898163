
import { computed, defineComponent, PropType } from 'vue';

import { Video } from '@/types';

export default defineComponent({
  props: {
    video: {
      type: Object as PropType<Video>,
      required: true,
    },
  },
  setup(props) {
    const url = computed<string>(() => {
      console.log(props.video.thumbnails.standard?.url || '');
      return props.video.thumbnails.standard?.url || '';
    });

    return { url };
  },
});
